import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Grid
} from "@material-ui/core";

// import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setDocumentExpireDate } from "../../store/action";
// import { addDocumentExpireDate } from "../../store/action/signee/signeeAction";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const CreateDatePickerEvent = ({ open, handleClose, signee_id,expire_date,setDatePicker }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [eventMsg, seteventMsg] = useState(false);
//   const { addDocExpireDateSuccess, addDocExpireDateError } = useSelector(
//     (state) => state.signee
//   );
  
  const [data, setData] = useState({
    document_id:expire_date.document_id ? expire_date.document_id.id : null,
    signee_id: signee_id,
    document_subkey:expire_date.document_subkey,
    expire_date:expire_date.expire_date
  });
  const [formError, setError] = useState([]);

  useEffect(()=>{
    setData({
        document_id:expire_date.document_id ? expire_date.document_id.id : null,
        signee_id: signee_id,
        document_subkey:expire_date.document_subkey,
        expire_date:expire_date.expire_date
      })
},[expire_date])

const handleChange = (event) => {
  console.group(event.target.name, event.target.value );
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const onSubmitEvent = async (event) => {
    event.preventDefault();
    data.signee_id = signee_id;
    if (data.comment === "") {
      setError("err_comment");
    } else {
        const data1 = { expire_date: data?.expire_date, id: data?.document_id };
        dispatch(setDocumentExpireDate(data1));
        seteventMsg(true);
        handleClose();
        setData("");
    }
  };
  const closeModal = () => {
    resetForm();
    setData("");
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData({ ...data, expire_date: "" });
  };
  return (
    <>
      {/* {eventMsg && addDocExpireDateError?.message && (
        <Notification data={addDocExpireDateError?.message} status="error" />
      )}
      {eventMsg && addDocExpireDateSuccess?.message && (
        <Notification
          data={addDocExpireDateSuccess?.message}
          status="success"
        />
      )} */}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitEvent(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Set Expire Date</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
               <Grid item xs={8} sm={8}>
                  <TextField
                    required
                    id="expire_date"
                    type="date"
                    label="Expire Date"
                    name="expire_date"
                    variant="outlined"
                    margin="dense"
                    defaultValue={data?.expire_date}
                    value={data?.expire_date}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    dateFromat='dd-MM-YYYY'
                    onChange={handleChange}
                  />
               </Grid>
            </div>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
        
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button onClick={resetForm} color="secondary" variant="contained" type="reset">
              RESET
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              SUBMIT
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateDatePickerEvent;
