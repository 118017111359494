export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const FORGOT_REQUEST = 'FORGOT_REQUEST';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';


export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const CHANGE_PASS_REQUEST = 'CHANGE_PASS_REQUEST';
export const CHANGE_PASS_ERROR = 'CHANGE_PASS_ERROR';


export const GET_PREFERENCE_REQUEST = "GET_PREFERENCE_REQUEST";
export const GET_PREFERENCE_SUCCESS = 'GET_PREFERENCE_SUCCESS';
export const GET_PREFERENCE_ERROR = "GET_PREFERENCE_ERROR";

export const CREATE_PREFERENCE_REQUEST = "CREATE_PREFERENCE_REQUEST";
export const CREATE_PREFERENCE_SUCCESS = 'CREATE_PREFERENCE_SUCCESS';
export const CREATE_PREFERENCE_ERROR = "CREATE_PREFERENCE_ERROR";

export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_ERROR = "GET_ORGANIZATION_ERROR";

export const GET_SHIFT_REQUEST = "GET_SHIFT_REQUEST";
export const GET_SHIFT_SUCCESS = "GET_SHIFT_SUCCESS";
export const GET_SHIFT_ERROR = "GET_SHIFT_ERROR";

export const GET_SHIFT_DETAIL_REQUEST = "GET_SHIFT_DETAIL_REQUEST";
export const GET_SHIFT_DETAIL_SUCCESS = "GET_SHIFT_DETAIL_SUCCESS";
export const GET_SHIFT_DETAIL_ERROR = "GET_SHIFT_DETAIL_ERROR";

export const GET_HOSPITAL_REQUEST = "GET_HOSPITAL_REQUEST";
export const GET_HOSPITAL_SUCCESS = "GET_HOSPITAL_SUCCESS";
export const GET_HOSPITAL_ERROR = "GET_HOSPITAL_ERROR";


export const GET_FILTER_SPECIALITY_REQUEST = "GET_FILTER_SPECIALITY_REQUEST";
export const GET_FILTER_SPECIALITY_SUCCESS = "GET_FILTER_SPECIALITY_SUCCESS";
export const GET_FILTER_SPECIALITY_ERROR = "GET_FILTER_SPECIALITY_ERROR";


export const FILTER_SHIFT_REQUEST = "FILTER_SHIFT_REQUEST";
export const FILTER_SHIFT_SUCCESS = "FILTER_SHIFT_SUCCESS";
export const FILTER_SHIFT_ERROR = "FILTER_SHIFT_ERROR";


export const ADD_DOCUMENT_REQUEST = "ADD_DOCUMENT_REQUEST";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_ERROR = "ADD_DOCUMENT_ERROR";


export const ADD_ANOTHER_ORG_REQUEST = "ADD_ANOTHER_ORG_REQUEST";
export const ADD_ANOTHER_ORG_SUCCESS = "ADD_ANOTHER_ORG_SUCCESS";
export const ADD_ANOTHER_ORG_ERROR = "ADD_ANOTHER_ORG_ERROR";


export const UPDATE_SPECIALITY_REQUEST = "UPDATE_SPECIALITY_REQUEST";
export const UPDATE_SPECIALITY_SUCCESS = "UPDATE_SPECIALITY_SUCCESS";
export const UPDATE_SPECIALITY_ERROR = "UPDATE_SPECIALITY_ERROR";


export const GET_SIGNEE_SPE_REQUEST = "GET_SIGNEE_SPE_REQUEST";
export const GET_SIGNEE_SPE_SUCCESS = "GET_SIGNEE_SPE_SUCCESS";
export const GET_SIGNEE_SPE_ERROR = "GET_SIGNEE_SPE_ERROR";

export const DOCUMENT_DETAIL_REQUEST = "DOCUMENT_DETAIL_REQUEST";
export const DOCUMENT_DETAIL_SUCCESS = "DOCUMENT_DETAIL_SUCCESS";
export const DOCUMENT_DETAIL_ERROR = "DOCUMENT_DETAIL_ERROR";


export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR";

export const SWITCH_ACCOUNT_REQUEST = "SWITCH_ACCOUNT_REQUEST";
export const SWITCH_ACCOUNT_SUCCESS = "SWITCH_ACCOUNT_SUCCESS";
export const SWITCH_ACCOUNT_ERROR = "SWITCH_ACCOUNT_ERROR";

export const APPLY_SHIFT_REQUEST = "APPLY_SHIFT_REQUEST";
export const APPLY_SHIFT_SUCCESS = "APPLY_SHIFT_SUCCESS";
export const APPLY_SHIFT_ERROR = "APPLY_SHIFT_ERROR";

export const ADD_NEW_NOTIFICATION_FAIL = "ADD_NEW_NOTIFICATION_FAIL";
export const ADD_NEW_NOTIFICATION_SUCCESS = "ADD_NEW_NOTIFICATION_SUCCESS";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const CONFIRM_BOOK_REQUEST = "CONFIRM_BOOK_REQUEST";
export const CONFIRM_BOOK_SUCCESS = "CONFIRM_BOOK_SUCCESS";
export const CONFIRM_BOOK_ERROR = "CONFIRM_BOOK_ERROR";

export const GET_MY_SHIFT_LIST = "GET_MY_SHIFT_LIST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";

export const SET_DOCUMENT_EXPIRE_ERROR = "SET_DOCUMENT_EXPIRE_ERROR";
export const SET_DOCUMENT_EXPIRE_REQUEST = "SET_DOCUMENT_EXPIRE_REQUEST";
export const SET_DOCUMENT_EXPIRE_SUCCESS = "SET_DOCUMENT_EXPIRE_SUCCESS";